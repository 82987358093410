import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from '@/views/Home'
import About from '@/views/About'
import Dtcenter from '@/views/Dtcenter'
import Products from '@/views/Products'
import { Audit, FaceSign, Lease, Custom } from '@/views/prods'

export const routes = [
  {
    path: "/",
    exact: true,
    component: Home
  },
  {
    path: "/products",
    component: Products,
    routes: [
      {
        path: "/products/audit.html",
        component: Audit
      },
      {
        path: "/products/sign.html",
        component: FaceSign
      },
      {
        path: "/products/lease.html",
        component: Lease
      },
      {
        path: "/products/custom.html",
        component: Custom
      },
      
    ]
  },
  {
    path: "/dtcenter.html",
    component: Dtcenter
  },
  {
    path: "/about.html",
    component: About
  },
];

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

export default function RouteView(props) {
  const { routes } = props
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
}