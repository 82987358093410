// 通用接口
import { request } from 'smartfetch'

// export function sendUserInfo (data) {
//   return request('', data, 'POST')
// }

// 发送短信验证码
export function sendVerifyCode (data) {
  return request('website/sms/send', data, 'POST')
}

// 发送使用信息
export function sendUserInfo (data) {
  return request('website/trial-busness/apply', data, 'GET')
}