import React, { PureComponent } from 'react'
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import PropTypes from 'prop-types'
import './index.scss'

const defOpts = {
  loop: false, // 循环播放
  lazy: true, // 延时加载
  // 如果需要分页器

  // 如果需要前进后退按钮
  autoplay: true
}

export default class YcSwiper extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount () {
    this.swiper = new Swiper(this.refs[this.props.refName], this.defOpts())
  }
  defOpts () { // 合并swiper默认配置
    return Object.assign({}, defOpts, this.props.options)
  }
  componentDidUpdate () {
    //this.swiper.view(0)
    this.swiper.update()
  }
  componentWillUnmount () {
    this.swiper && this.swiper.destroy()
  }
  render () {
    return (
      <div 
        className={`yc-page-swiper swiper-container ${this.props.className}`}
        ref={this.props.refName}
      >
        {this.props.children}
      </div>
    )
  }
}

YcSwiper.propTypes = {
  options:  PropTypes.object,
  children: PropTypes.node
}