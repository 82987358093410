import MiddleContainer from '@/components/MiddleContainer'
import React, { PureComponent, Fragment } from 'react'
import { Divider } from 'antd';

const Wrapper = ({ className, children }) => (
  <div className={className}>
    <MiddleContainer>
      {children}
    </MiddleContainer>
  </div>
)
export default Wrapper