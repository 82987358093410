import React, { PureComponent } from 'react'
import { Button } from 'antd'

export default class TickerButton extends PureComponent {
  constructor (props) {
    super(props)
    const { interval, total } = this.props
    this.$data = {
      ticker: 0,
      interval: interval || 1000,
      total: total || 60000
    }
    this.state = {
      isSettime: false,
      leftTime: this.$data.total
    }
    
  }
  componentDidMount () {
    this.props['auto-send'] && this.handleBtnClick()
  }
  updateTickerTime = () => {
    const { interval, total } = this.$data
    const { leftTime } = this.state
    const newLeftTime = leftTime - interval
    if (newLeftTime <= 0) {
      this.setState({ isSettime: false, leftTime: total })
      clearTimeout(this.$data.ticker)
    } else {
      this.setState({ leftTime: newLeftTime })
      this.$data.ticker = setTimeout(this.updateTickerTime, this.$data.interval)
    }
  }
  handleBtnClick = () => {
    this.setState({ isSettime: true })
    setTimeout(this.updateTickerTime, this.$data.interval)
    const { onClick } = this.props
    typeof onClick === 'function' && onClick()
  }
  render () {
    const { isSettime, leftTime } = this.state
    const leftSec = leftTime % 1000 === 0 ? leftTime / 1000 : (leftTime / 1000).toFixed(1)
    return (
      <Button className="yc-ticker-button" {...this.props} disabled={isSettime} onClick={this.handleBtnClick} >
        {isSettime ? `${leftSec}s后重发` : this.props.children}
      </Button>
    )
  }
  
}