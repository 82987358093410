import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";

export default class NavMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expSubMenu: "",
    };
  }
  expandeSubMenu(key) {
    this.setState({ expSubMenu: this.state.expSubMenu !== key ? key : "" });
  }
  unexpandSubMenu = () => {
    this.setState({ expSubMenu: "" });
  };
  render() {
    const { navLinks, className, unexpandSubMenu, isSub } = this.props;
    const { expSubMenu } = this.state;
    return (
      <ul className={`${className} nav-menu clearfix`}>
        {navLinks.map((item) => {
          const navLinkCreater = (item, className) => (
            <NavLink
              className={className}
              activeClassName="is-active"
              to={item.to}
              exact={item.exact}
              onClick={
                isSub && !item.onClick
                ? unexpandSubMenu
                : item.onClick
                ? () => {
                  item.onClick()
                  unexpandSubMenu ? unexpandSubMenu() : this.unexpandSubMenu()
                }
                : this.unexpandSubMenu
              }
            >
              {item.title}
            </NavLink>
          );
          return (
            <li className="nav-menu-item" key={item.title}>
              {item.subLinks ? (
                <div
                  className={`sub-nav-menu${
                    item.to === expSubMenu ? " is-expanded" : ""
                  }`}
                >
                  <div
                    className="sub-nav-title"
                    onClick={this.expandeSubMenu.bind(this, item.to)}
                  >
                    {navLinkCreater(item, "sub-nav-link")}
                    <div className="item-cover"></div>
                  </div>
                  <NavMenu
                    className="sub-nav"
                    isSub={true}
                    unexpandSubMenu={this.unexpandSubMenu}
                    navLinks={item.subLinks}
                  ></NavMenu>
                </div>
              ) : (
                navLinkCreater(item)
              )}
            </li>
          );
        })}
      </ul>
    );
  }
}
