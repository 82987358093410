import React from 'react'
import './index.scss'

export default function ProdsHeaderContainer (props) {
  const { systemNameImg, systemDes, posterImg} = props.url
  return (
    <section className="prods" >
      <div className="yc-prods-header-container">
        <div className="describe">
          <p><img alt="产品名称" src={ systemNameImg } ></img></p>
          <p><img alt="产品描述" src={ systemDes } ></img></p>
        </div>
        <div className="prods-img">
          <img src={posterImg}/>
        </div>
      </div>
    </section>
  )
}