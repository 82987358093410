import React, { PureComponent } from 'react'
import RouteView from '@/router'
import './index.scss'
export default class Products extends PureComponent {
  render () {
    const { routes } = this.props
    return (
      <div>
        <RouteView routes={routes}></RouteView>
      </div>
    )
  }
}