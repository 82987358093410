import partner1 from './images/partner1.png'
import partner2 from './images/partner2.png'
import partner3 from './images/partner3.png'
import partner4 from './images/partner4.png'
import partner5 from './images/partner5.png'
import partner6 from './images/partner6.png'
import partner7 from './images/partner7.png'

import banner1 from './images/P1.png'
import banner2 from './images/P2.jpg'
import banner3 from './images/P3.jpg'
import banner4 from './images/P4.png'
import banner5 from './images/P5.jpg'

export const cardLists = () => [
  {
    iconClass: 'ywb-icon-shouye-linrunshenpixitong',
    text: '信贷审批系统',
    hoverText: '数字化智能业务系统，覆盖业务全生命周期，专业、高效、全能的审批系统',
    btnLink: '/products/audit.html'
  },
  
  {
    iconClass: 'ywb-icon-shouye-rongzizulin',
    text: '融资租赁系统',
    hoverText: '业务全生命周期管理——全方位关注汽车生活，以租代买，盘活汽车新市场',
    btnLink: '/products/lease.html'
  },
  {
    iconClass: 'ywb-icon-shouye-yinhangmianqian',
    text: '银行面签系统',
    hoverText: '远程"三亲见"规范——便捷迅速、全平台覆盖，实时通讯技术打通会话',
    btnLink: '/products/sign.html'
  },
  // {
  //   iconClass: 'ywb-icon-shouye-gonghangefenqi',
  //   text: '工行e分期',
  //   hoverText: '数字化智能业务系统——对接信贷审批系统，专业服务于我们的客户',
  //   btnLink: '/products/audit.html'
  // },
  {
    iconClass: 'ywb-icon-chuangjianbiaodan',
    text: '自定义表单系统',
    hoverText: '无需代码，即可快速、灵活地构建业务管理系统，操作简单，满足企业个性化管理需求',
    btnLink: '/products/custom.html'
  },
]
export const afterProducts = () => [
  // { iconClass: 'ywb-icon-shouye-miaopimiaodai', name: '秒批秒贷'},
  { iconClass: 'ywb-icon-shouye-daihouchanpin', name: '资产管理平台'},
  { iconClass: 'ywb-icon-shouye-kaifangpingtai', name: '开放平台'},
  { iconClass: 'ywb-icon-shouye-Cduanapp', name: 'C端APP'}
]
export const partnerLogos = () => [partner1, partner2, partner3, partner4, partner5, partner6, partner7]
export const bannerList = () => [banner1, banner2, banner3, banner4, banner5]