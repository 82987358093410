import React from 'react'
import ProdsHeaderContainer from '../components/ProdsHeaderContainer'
import ProdsNav from '../components/ProdsNav'
import systemImg from '../images/custom/system-img.png'
import systemNameImg from '../images/custom/chanpin-rongziwenzi1.png'
import systemDes from '../images/custom/chanpin-rongziwenzi2.png'
import posterImg from '../images/custom/chanpin-rongzitu.png'
export default function (props) {
  const navList = ['产品介绍', '业务流程', '系统特色','']
  const introduceContent = {
    introduce: {
      
      systemName: '自定义表单系统',
      iconName: 'ywb-icon-rongzizulinxitong-rongzizulin',
      text: '亿车自定义表单系统集合设计器、流程引擎、智能报表等功能，帮助企业快速搭建个性化应用，解决传统软件开发周期长、成本高、实施空难大的问题，并且提供丰富接口，无缝集成现有业务系统，打破信息孤岛，整合企业数据中台。拖拽的表单设计，丰富的布局方式，多样的字段类型，可灵活设置数据联动、自定义检验规则，助你轻松搭建强大、美观的平台。',
      imgUrl: systemImg
    },
    progress: [{
      iconName: 'ywb-icon-xitongshezhi',
      text: '系统设置',
      show:1
    },{
      iconName: 'ywb-icon-chuangjianbiaodan',
      text: '创建表单',
      show:1
    },{
      iconName: 'ywb-icon-guanlianliucheng',
      text: '关联流程',
      show:1
    },{
      iconName: 'ywb-icon-shujufenxi',
      text: '数据分析',
      show:1
    }],

    special: [
      {
        iconName: 'ywb-icon-zujian',
        text: ['丰富的组建', '简单拖拽设置', '数据流动', '自定义检验规则']
      },{
        iconName: 'ywb-icon-liuchengkepei',
        text: ['流程可配', '节点流转分支可配', '节点权限可控','审批操作流完善']
      },{
        iconName: 'ywb-icon-shujutongji',
        text: ['数据应用统计', '多表关联查询', '权限设置明确','可视化图表展示']
      },
    ],
    framework: {
      frameworkName: 'custom',Num:3,
    }
  }
  return (
    <div>
      <ProdsHeaderContainer url={ { systemNameImg, systemDes, posterImg } }/>
      <ProdsNav navList={ navList } prodsIntroduce={ introduceContent } name="自定义表单系统"/>
    </div>
  )
}