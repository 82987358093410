import React, { PureComponent } from 'react'
import MiddleContainer  from '@/components/MiddleContainer'
import policeImg from './images/police.png'
import './index.scss'

export default class Footer extends PureComponent {
  constructor (props) {
    super(props);
    this.el = React.createRef();
  }
  render () {
    // const info = window.location.href
    return (
      <div ref={this.el} className={`yc-page-footer ${this.props.className}`}>
        <MiddleContainer>
          <div className="copyright-words">
            <p><a>地址：杭州市上城区城星路89号尊宝大厦银尊3309室</a></p>
            <p style={{marginBottom:'10px'}}>
              <a>电话：15397068207</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a>邮箱：kefu20@yicheshuke.com</a>
            </p>
            <p>
              <a target="_blank" href="http://beian.miit.gov.cn/">Copyright ©2020 浙ICP备20008300号-2</a> &nbsp;
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010302003562">
                <img src={policeImg} />
                <span>浙公网安备 33010302003562号</span>&nbsp;
              </a>
              隐私声明  资质证照
            </p>
          </div>
        </MiddleContainer> 
      </div>
    )
  }
}

