export const jobLists = [
  { name: '职位名称', city: '工作地点', update: '更新时间', link: '111', isHeader: true },
  { name: '资深数据开发工程师', city: '杭州', update: '2019-06-12', link: 'https://www.zhipin.com/gongsi/01ba830818a354581HZy2Ny7GA~~.html?ka=company-intro' },
  { name: 'PHP开发工程师', city: '杭州', update: '2019-06-12', link: 'https://www.zhipin.com/gongsi/01ba830818a354581HZy2Ny7GA~~.html?ka=company-intro' },
  { name: '用户体验设计师', city: '杭州', update: '2019-06-12', link: 'https://www.zhipin.com/gongsi/01ba830818a354581HZy2Ny7GA~~.html?ka=company-intro' },
  { name: '视觉设计师', city: '杭州', update: '2019-06-12', link: 'https://www.zhipin.com/gongsi/01ba830818a354581HZy2Ny7GA~~.html?ka=company-intro' },
]

let urlTemp ='./images/activity'
let urlTempUpdate ='./images/activity_update'
// export const activityImgs = [
//   { style: { width: 400, height: 300, top: 0, left: 0}, url: require(urlTemp +'1.png') },
//   { style: { width: 400, height: 190, top: 0, left: 420}, url: require(urlTemp + '2.png') },
//   { style: { width: 360, height: 400, top: 0, left: 840}, url: require(urlTemp + '3.png') },
//   { style: { width: 400, height: 270, top: 320, left: 0}, url: require(urlTemp + '4.png') },
//   { style: { width: 400, height: 190, top: 210, left:420}, url: require(urlTemp + '5.png') },
//   { style: { width: 400, height: 170, top: 420, left: 420}, url: require(urlTemp + '6.png') },
//   { style: { width: 360, height: 170, bottom: 0, right: 0}, url: require(urlTemp + '7.png') },
// ]

export const activityImgs = [
  { style: { width: 400, height: 240, top: 0, left: 0}, url: require(urlTempUpdate +'1.png') },
  { style: { width: 400, height: 190, top: 0, left: 420}, url: require(urlTempUpdate + '2.png') },
  { style: { width: 360, height: 320, top: 0, left: 840}, url: require(urlTempUpdate + '3.png') },
  { style: { width: 400, height: 150, top: 260, left: 0}, url: require(urlTempUpdate + '4.png') },
  { style: { width: 400, height: 190, top: 210, left:420}, url: require(urlTempUpdate + '5.png') },
  { style: { width: 400, height: 160, top: 430, left: 0}, url: require(urlTempUpdate + '6.png') },
  { style: { width: 400, height: 170, bottom: 0, right: 380}, url: require(urlTempUpdate + '7.png') },
  { style: { width: 360, height: 240, bottom: 0, right: 0}, url: require(urlTempUpdate + '8.png') },
]
