import React from 'react'
import MiddleContainer  from '@/components/MiddleContainer'
import { Button } from 'antd'
import './index.scss'

export default function TryAndMap (props) {
  const { probationShow } = props
  return (
    <section className="yc-try-and-map">
      <section className="try-module">
        <MiddleContainer>
          <p>亿车数科-数字科技让汽车生活更简单</p>
          <Button className="theme-linear-button try-btn" type="primary" onClick={probationShow}>申请使用</Button>
        </MiddleContainer>
      </section>
      {/*<section className="map-module">*/}
      {/*  <MiddleContainer>*/}
      {/*    /!* <div className="company-info-pane clearfix">*/}
      {/*      <div className="location-img"></div>*/}
      {/*      <div className="company-info">*/}
      {/*        <p className="title">联系我们</p>*/}
      {/*        <p>地址：浙江省杭州市朝晖路179号嘉汇大厦2号楼26楼</p>*/}
      {/*        <p>电话：0571-58102270</p>*/}
      {/*        <p>时间：09：00 - 17：30</p>*/}
      {/*        <p>邮箱：kf@yiche99.com</p>*/}
      {/*      </div>*/}
      {/*    </div> *!/*/}
      {/*  </MiddleContainer>*/}
      {/*</section>*/}
    </section>
  )
}