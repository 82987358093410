import React, { useState } from "react";
import MiddleContainer from "@/components/MiddleContainer";
import NavMenu from "./components/NavMenu";
import "./index.scss";
import { Modal, Button } from "antd";

const themeClassNames = {
  dark: "dark",
  light: "light",
};

export default function Header(props) {
  const theme = "";
  const [visible, setVisible] = useState(false);
  const handleOk = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleDownload = (index) => {
    if(index === 1) {
      window.open('https://yiche-static.linrunwanche.com/yiche/uploads/pdf/亿车数科—汽车金融系统宣传手册.pdf')
    } else if(index === 2) {
      window.open('https://yiche-static.linrunwanche.com/yiche/uploads/pdf/亿车数科—自定义表单宣传手册.pdf')
    }
  };
  const navLinks = [
    { to: "/", title: "首页", exact: true },
    {
      to: "/products",
      title: "公司产品",
      subLinks: [
        { to: "/products/audit.html", title: "信贷审批系统" },
        { to: "/products/sign.html", title: "银行面签系统" },
        { to: "/products/lease.html", title: "融资租赁系统" },
        { to: "/products/custom.html", title: "自定义表单系统" },
      ],
    },
    { 
      to: "/dtcenter.html", 
      title: "亿车商",
      subLinks: [
        { 
          to: "", 
          title: "亿车商APP",
          onClick: () => {
            window.open('http://www.yicheshang.com/')
          }
        },
        { 
          to: "",
          title: "车粒子智慧门店",
          onClick: () => {
            window.open('http://yicheshang.com/activity')
          }
        },
      ],
    },
    { to: "/about.html", title: "关于我们" },
    {
      to: "",
      title: "文档",
      onClick: () => {
        setVisible(true);
      },
    },
  ];
  return (
    <div
      className={`yc-page-header ${
        themeClassNames[theme] || themeClassNames.dark
      }-header clearfix`}
    >
      <MiddleContainer>
        <div className="logo-pane">
          <img src={require("./images/logo.png")} />
        </div>
        <NavMenu className="header-nav-menu" navLinks={navLinks}></NavMenu>
      </MiddleContainer>
      <Modal 
        visible={visible} 
        title="文档" 
        onOk={handleOk} 
        onCancel={handleCancel}
        maskClosable={false}
        cancelText="取消"
        okText="确定"
        style={{ top: 200}}
        width={600}
        footer={[<Button key="back" onClick={handleCancel}>取消</Button>]}
      >
        <ul className="ul-list">
          <li>
            <img src="https://yiche-static.linrunwanche.com/yiche/uploads/image/20210108/0e6c266eb3b0aa23d2905f07aaa8f286.png" />
            <div className="title">亿车数科—汽车金融系统宣传手册</div>
            <div className="btn-wrap">
              <Button onClick={() => handleDownload(1)}>下载</Button>
            </div>
          </li>
          <li>
            <img src="https://yiche-static.linrunwanche.com/yiche/uploads/image/20210108/0e6c266eb3b0aa23d2905f07aaa8f286.png" />
            <div className="title">亿车数科—自定义表单宣传手册</div>
            <div className="btn-wrap">
              <Button onClick={() => handleDownload(2)}>下载</Button>
            </div>
          </li>
        </ul>
      </Modal>
    </div>
  );
}
