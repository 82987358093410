import React, { PureComponent } from 'react'
import MiddleContainer from '@/components/MiddleContainer'
import { Button } from 'antd'
import { jobLists, activityImgs } from './dataHandle'
import './index.scss'

export default class About extends PureComponent {
  render () {

    return (
    <div className="about-page">
      <div className="banner"></div>
      <MiddleContainer>
        <div className="company-intro">
          <h3>公司简介</h3>
          <p className="text">
          {/* 亿车数科成立于2017年10月，定位为汽车经销商科技金融服务平台。专注于渗透汽车交易场景，为车商、金融商提供多维度工具及数据解决方案。专注于将纷繁复杂的业务场景、数据、风控、资金、外部生态服务等金融关键要素予以标准化，打造资产端到资金端整体解决方案，帮助客户快速实现智能化转型，提升商业价值和竞争力。亿车数科更是一家汽车金融科技赋能整体解决方案提供商，我们为银行、汽车金融等机构提供高效的智能零售金融解决方案和资产、资金连接服务，公司产品范围涵盖汽车金融进件系统、汽车金融贷后管理系统、融资租赁、银行面签等多个领域，并提供产品研发、获客引流、AI风控管理、管理监控等多种工具。 */}
          亿车数科成立于2017年10月，总部设立在杭州，是国内数字科技综合解决方案的开拓者，更是依托数字科技服务于汽车、手机、消费金融等领域的智能化连接平台。

          </p>
          <p>
            公司以人工智能（AI）、大数据、云计算等科技优势，高效连接资金端和资产端，为银行、经销商、融资担保、保理、融资租赁公司等提供汽车、手机分期业务SAAS系统、大数据智能风控等服务，实现运营、决策、风控的一体化、平台化与生态化，帮助客户快速实现数字化转型，提升商业价值和竞争力。
            亿车数科成功服务于中国工商银行、林润万车、海蕴科技等战略合作伙伴，服务客户累计交易总额超100亿元人民币，公司于2019年成功迈入“国家高新技术企业”行列。
          </p>
          <div className="culture">
            <p className="logo">企业文化</p>
            <p>使命—— ——数字科技让汽车生活更简单</p>
            <p>愿景—— ——做客户首选的数字科技服务品牌</p>
            <p>价值观—— ——客户第一、拥抱变化、团队协作、简单极致</p>
            {/* <span className="slogan">数字科技让汽车生活更简单  汽车数字科技服务平台</span> */}
          </div>
        </div>
        <div className="activity">
          <h3>公司活动&员工风采</h3>
          <div className="activity-content">
            {this.getActivityImgs()}
          </div>
        </div>
      </MiddleContainer>
      <div className="join-us">
        <MiddleContainer>
          <h3>加入我们</h3>
          <div className="jobs">
            <ul>{this.getJobNodes()}</ul>
          </div>
        </MiddleContainer>
      </div>
    </div>
    )
  }
  getActivityImgs () {
    const imgNodes = activityImgs.map(item => (
      <li key={item.url} style={item.style}>
        <img alt="" src={item.url}></img>
      </li>
    ))
    return(
      <ul>
        {imgNodes}
      </ul>
    )
  }
  getJobNodes () {
    const jobNodes = jobLists.map((item, index) => (
      <li key={index} className={`${item.isHeader ? 'job-header' : ''}`}>
        <div className="wraper">
          <span className="job-name">{item.name}</span>
          <span className="job-city">{item.city}</span>
          <span className="job-update">{ index === 0 ?  item.update : (new Date()).toLocaleDateString().replace(/\//g,'-')}</span>
          <span className="job-link"><a href={item.link} target="blank">申请职位</a></span>
        </div>
      </li>
    ))
    return jobNodes
  }
}