import { SmartFetch } from 'smartfetch'
import { notification  } from 'antd'
export * from './api'

const notifyQueue = []

export function notifyMsg (msg) {
  if (notifyQueue.includes(msg)) return
  notifyQueue.push(msg)
  notification.error({
    message: '系统提示',
    description: msg,
    customClass: 'smartfetch-system-message',
    onClose: () => {
      const msgIndex = notifyQueue.indexOf(msg)
      msgIndex >= 0 && notifyQueue.splice(msgIndex, 1)
    }
  })
}
const statusWarn = {
  '404': '请求地址不存在',
  '500': '服务器维护中，请稍后再试'
}

export function codeErrorHandler (response, curtomHandler) {
  console.log('----------------------',response,'====',window.API_CONFIG)
  const { 'error_no': errNo, 'error_msg': errMsg } = response
  let msg = errMsg || '请求失败'
  switch (errNo) {
    default:
      typeof curtomHandler === 'function' ? curtomHandler(errNo, notifyMsg) : notifyMsg(msg)
      break
  }
}

export { codeErrorHandler as errorHandler }

const { url } =  {"oss":"https://efq-api.saasyc.com/yiche/oss/config","url":"https://efq-api.saasyc.com/","ocr":"https://efq-api.saasyc.com/yiche/ocr/idcard"}
// window.API_CONFIG ||
// window.API_CONFIG || {"oss":"https://efq-api.saasyc.com/yiche/oss/config","url":"https://efq-api.saasyc.com/","ocr":"https://efq-api.saasyc.com/yiche/ocr/idcard"}

const configOpts = {
  baseConfig: [
    {
      key: 'default',
      baseURL: url
    }
  ],
  errorHandle: notifyMsg,
  resCheck: (res) => (res.success || res.error_no === 200),
  statusWarn,
  dataKey: 'result',
  forceAxios: true,
  codeError: codeErrorHandler
}

const $fetch = new SmartFetch(configOpts)

export default $fetch.fetch