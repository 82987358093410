import React from 'react'
import ProdsHeaderContainer from '../components/ProdsHeaderContainer'
import ProdsNav from '../components/ProdsNav'
import systemImg from '../images/lease/system-img.png'
import systemNameImg from '../images/lease/chanpin-rongziwenzi1.png'
import systemDes from '../images/lease/chanpin-rongziwenzi2.png'
import posterImg from '../images/lease/chanpin-rongzitu.png'
export default function (props) {
  const navList = ['产品介绍', '产品业务流程', '产品架构', '系统特色', '应用场景']
  const introduceContent = {
    introduce: {
      Num:5,
      systemName: '融资租赁系统',
      iconName: 'ywb-icon-rongzizulinxitong-rongzizulin',
      text: '为汽车厂商、第三方金融公司及汽车租赁公司等提供专业的汽车金融解决方案，融资租赁业务全生命周期管理。全方位关注汽车生活，以租代买，盘活汽车新市场，攻占汽车行业全方位战略据点。',
      imgUrl: systemImg
    },
    
    progress: [{
      iconName: 'ywb-icon-yijianfaqishenqing',
      text: '一键发起申请'
    },{
      iconName: 'ywb-icon-rongzizulinxitong-rongzizulin',
      text: '亿车融资租赁系统'
    },{
      iconName: 'ywb-icon-rongzizulinxitong-jiaoyichenggong',
      text: '交易成功'
    }],
    special: [
      {
        iconName: 'ywb-icon-rongzizulinxitong-shuangbaobiao',
        text: ['双报表分析展示', '权限数据安全控制', '工作流引擎和预警提醒']
      },{
        iconName: 'ywb-icon-rongzizulinxitong-zulinbaojia',
        text: ['租赁报价设计', '租赁业务受理', '财务一体化']
      },{
        iconName: 'ywb-icon-rongzizulinxitong-yuanchengjiankong',
        text: ['租赁物远程监控', '还款提醒及催收服务', '客户信用评价体系']
      },{
        iconName: 'ywb-icon-rongzizulinxitong-duoxitongjicheng',
        text: ['多系统集成', 'ERP/CRM系统', '第三方支付系统等']
      }
    ],
    scene:[
      {
        iconName: 'ywb-icon-rongzizulinxitong-shouhouhuizu',
        text: '售后回租'
      },{
        iconName: 'ywb-icon-rongzizulinxitong-rongzizulin',
        text: '融资租赁'
      },{
        iconName: 'ywb-icon-chelianganjie',
        text: '车辆按揭贷'
      }
    ],
    framework: {
      frameworkName: 'lease',Num:5,
    }
  }
  return (
    <div>
      <ProdsHeaderContainer url={ { systemNameImg, systemDes, posterImg } }/>
      <ProdsNav navList={ navList } prodsIntroduce={ introduceContent } name="融资租赁系统"/>
    </div>
  )
}