/**
 * 本系统中用于表单验证所需要的方法
 */
/** 表单通用验证规则 **/
export const regexMap = {
  /* 手机号正则 */
  telphone: /^1\d{10}$/,
  /* 身份证正则 */
  idCard: /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i,
  alphanumeric: /^[A-Za-z0-9]+$/,
  alpha: /^[A-Za-z]+$/,
  integerFn: (positive) => new RegExp(`${!positive && '-'}^[1-9]\\d*$`),
  integer: /^-?[1-9]\d*$/,
  float: /^-?([1-9]\d*\.\d*|0\.\d*[1-9]\d*|0?\.0+|0)$/,
  alphaArrStr: /^([a-zA-Z]+)+(,[a-zA-Z]+)*$/,
  homePhone: /^0\d{2,3}-([2-9][0-9]{6,7})$/,
  password: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/,
  nonnegative:  /^(([1-9]+)|([0-9]+\.[0-9]{1,2}))$/
}
/** 通用验证规则 **/
export const regexRuleCreater = (key, message = '内容格式有误', trigger = 'blur') => ({ pattern: regexMap[key], message, trigger })

export function numberCheck (type = 'number', message = '填入有效的数字') {
  type = type === 'number' ? ['integer', 'float'] : type
  return function (rule, value, callback) {
    // 校验非负数
    const typeChkResult = Array.isArray(type) ? type.some(fn => regexMap[fn].test(value)) : regexMap[fn].test(value)
    if (value && !typeChkResult) {
      return callback(new Error(message))
    } else {
      callback()
    }
  }
}

export function checkoutCommonRules (keys) {
  const rules = {
    require: { required: true, message: '请输入有效内容', trigger: 'blur' },
    number: { validator: numberCheck('number'), trigger: 'blur' },
    integer: { validator: numberCheck('integer', '请输入正整数'), trigger: 'blur' },
    notLessZero: { validator: numberCheck('number', '输入数值必须大于0'), trigger: 'blur' },
    numberRequired: { type: 'number', message: '请输入有效数字', trigger: 'blur' },
    numberRange: (min, max) =>({ type: 'number', min, max, message: typeof min === 'number' && typeof max === 'number' ? `请输入在${min}~${max}范围的数` : (typeof min === 'number'? `请输入大于${min}的数` :`请输入小于${max}的数`), trigger: 'blur' })
  }
  const getRule = (key, ...args) =>typeof rules[key] === 'function' ? rules[key](...args) : rules[key]
  if (Array.isArray(keys)) {
    return keys.map(item => getRule(...(Array.isArray(item) ? item : [item])))
  } else if (keys && typeof keys === 'object') {
    return Object.keys(keys).map(item => (Object.assign({}, rules[item] ? Object.assign({}, getRule(item, ...(keys[item].args || [])), keys[item].customRule) : keys[item])))
  }
  return Object.values(rules)
}
