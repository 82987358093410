import React, { Component } from 'react'
import { Form } from 'antd'
import SendInfos from './SendInfos'
import PhoneVerify from './PhoneVerify'

class FormFields extends Component {
  render () {
    const { step } = this.props
    return (
      <Form
        className="probation-model"
      >
        {step === 1 ? <PhoneVerify {...this.props}></PhoneVerify> : <SendInfos {...this.props}></SendInfos> }
      </Form>
    )
  }
}

export default Form.create({ name: 'form_fileds' })(FormFields);