import React, { PureComponent, Fragment } from 'react'
import { ProbationContext } from '@/contexts'
import YcSwiper from '@/components/YcSwiper'
import MiddleContainer from '@/components/MiddleContainer'
import InnerBox from './components/InnerBox'
import YcCard from './components/YcCard'
import Wrapper from './components/Wrapper'
import { Button } from 'antd'
import './index.scss'
import { cardLists, afterProducts, partnerLogos, bannerList } from './dataHandle'


export default class Home extends PureComponent {
  static contextType = ProbationContext;
  render () {
    return (
      <div className={'home-page'}>
        <div className={'banner'}>
          { this.getBannerNodes() }
        </div>
        <Wrapper className="introduce">
          <h3>了解我们的产品与服务</h3>
          <div>
            { this.getIntroNodes() }
          </div>
        </Wrapper>
        <Wrapper className="partner">
          <h3>合作伙伴</h3>
          <div className="partner-exhibition">
            { this.getPartnerNodes() }
          </div>
        </Wrapper>
      </div>
    )
  }
  getBannerNodes () {
    const bannerConfig = [
      {content: null}, 
      { content: <Button onClick={this.context}>申请使用</Button> }, 
      {content: null}
    ]
    const slideNodes = bannerConfig.map((item, index) => {
      return (<div className={'swiper-slide'} key={index}>
      <InnerBox content={item.content} index={index+1}></InnerBox>
    </div>)
    })
    const options = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    }
    const bannerNode = bannerList().map((item, index) => (<div className={'swiper-slide'} key={index}><a><img src={item}/></a></div>))
    return (
      <YcSwiper 
        className={'banner-swiper'} 
        refName='bannerSpContainer' 
        options={options}
      >
        <div className={'swiper-wrapper'}>
          {bannerNode}
        </div>
        <div className={'swiper-pagination'}></div>
      </YcSwiper>
    )
  }
  getIntroNodes () {
    const introNodes = cardLists().map((item, index) => <YcCard key={item.iconClass} className={`intro-card-${index+1}`} item={item}></YcCard>)
    return (
      <div className={'introduce-content clearfix'}>
        {introNodes}
        <div className={'after'}>
          <p className={'after-title'}>更多产品</p>
          <ul>
            {afterProducts().map((item, index) =>(
              <li key={index}>
                <span className={`after-icon ${item.iconClass}`}></span>
                <span className={'after-name'}>{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
  getPartnerNodes () {
    const options = {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      slidesPerView: 4, // 每一活动块显示图片数
      spaceBetween: 20, // 每张图片间距
      autoplay: false
    }
    const slideNodes =  partnerLogos().map((item, index) => (<div className={'swiper-slide'} key={index}><a><img src={item}/></a></div>))
    return (
      <YcSwiper 
        className={'partner-swiper'} 
        refName='partnerSpContainer'
        options={options}
      >
        <div className={'swiper-wrapper'}>
          { slideNodes }
        </div>
        <div className="swiper-button-prev ywb-icon-shangyige"></div>
        <div className="swiper-button-next ywb-icon-xiayige"></div>
      </YcSwiper>
    )
  }
}