import React from 'react'
import ProdsHeaderContainer from '../components/ProdsHeaderContainer'
import ProdsNav from '../components/ProdsNav'
import systemImg from '../images/faceSign/system-img.png'
import systemNameImg from '../images/faceSign/chanpin-bankwenzi1.png'
import systemDes from '../images/faceSign/chanpin-bankwenzi2.png'
import posterImg from '../images/faceSign/chanpin-banktu.png'
export default function (props) {
  const navList = ['产品介绍', '产品业务流程', '系统特色', '应用场景']
  const introduceContent = {
    introduce: {
      systemName: '银行面签系统',
      iconName: 'ywb-icon-shenfenshibie',
      text: '符合远程“三亲见”规范，为银行金融机构提供实时面签解决方案。采用实时通信技术，利用AI人脸识别、活体检测等科技核验会话双方身份。',
      imgUrl: systemImg
    },
    progress: [{
      iconName: 'ywb-icon-yijianfaqishenqing',
      text: '一键发起申请'
    },{
      iconName: 'ywb-icon-shenfenshibie',
      text: '亿车银行面签系统'
    },{
      iconName: 'ywb-icon-shenhetongguo',
      text: '审核通过'
    }],
    special: [
      {
        iconName: 'ywb-icon-shenfenshibie',
        text: ['身份识别', '智能AI人脸识别', '活体检测']
      },{
        iconName: 'ywb-icon-shishifankui',
        text: ['实时反馈', '运营成本低', '易于系统集成']
      },{
        iconName: 'ywb-icon-jiandankuaijie',
        text: ['简单快捷', '客户体验感好', '全平台覆盖']
      }
    ],
    scene:[
      {
        iconName: 'ywb-icon-chedaishenhe',
        text: '车贷审核'
      },{
        iconName: 'ywb-icon-fengkongshenhe1',
        text: '风控审核'
      },{
        iconName: 'ywb-icon-shenfenshibie',
        text: '银行面签'
      }
    ],
    framework: {
      frameworkName: 'faceSign',Num:5,
    }
  }
  return (
    <div>
      <ProdsHeaderContainer url={ { systemNameImg, systemDes, posterImg } }/>
      <ProdsNav navList={ navList } prodsIntroduce={ introduceContent } name="银行面签系统"/>
    </div>
  )
}