import React, { Component } from 'react'
import { Form, Input } from 'antd'
import TickerButton from '@/components/TickerButton'

export default class PhoneVerify extends Component {
  render () {
    const { form: { getFieldDecorator }, onVerifySend, telphone } = this.props;
    return (
      <div className="phone-verify-fields">
        <p>验证码已发送到手机号</p>
        <p className="phone-text">{telphone}</p>
        <Form.Item>
          {getFieldDecorator('verify_code', {
              rules: [
                { required: true, whitespace: true, message: '请输入验证码' }
              ]}
            )(<Input addonAfter={<TickerButton auto-send="true" type="link" size="small" onClick={onVerifySend}>重新发送</TickerButton>}></Input>)
          }
        </Form.Item>
      </div>
    )
  }
}