import React from 'react';
import './index.scss';
import prodsFlow from '../../images/prods-flow.png'
import prodsFrameworkFlow from '../../images/audit/prods-framework.png'
// 产品介绍
function IntroduceProds (props) {
  const { introduce } = props
  return (
    <div className="introduce-prods prods-pane">
      <div className="introduce-prods-text">
        <p className="title">产品介绍</p>
        <p className="system-name"><i className={introduce.iconName}></i>{introduce.systemName}</p>
        <article>
          { introduce.text }
        </article>
      </div>
      <div className="introduce-prods-img">
        <div className="img-container">
          <img alt="系统截图" src={ introduce.imgUrl } />
        </div>
        <div className="img-container-footer"></div>
      </div>
    </div>
  )
}
// 产品流程
function ProgressProds (props) {
  const { progress } = props
  return (
    <div className="progress-prods prods-pane">
      <p className="title">产品业务流程</p>
      <p className="progress-des">智能操作系统，便捷业务全流程</p>
      <div className="progress-step">
        {
          progress.map((item, index)=>(
            // {index.toString()}
            <div className={ item.show ? 'info' :'' } key={index.toString()}>
              <div className="circle" style={{marginLeft: item.iconName === 'ywb-icon-xindaishenpi' ? '18px':''}}><i className={item.iconName}></i></div>
              
              {/* <img  className="line" style={{ display: !item.show ? (index === 2 ? 'none':'') : (index === 3 && item.show === 2 ? 'none':'')}} alt="" src={prodsFlow} /> */}
              
              {
                !item.show ?  
                <img  className="line" style={{ display: (index === 2 ? 'none':'') }} alt="" src={prodsFlow} />
                :
                <img  className="line4" style={{ display: index === 3 ? 'none':''}} alt="" src={prodsFlow} />
              }
              <p>{ item.text }</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}
// 系统特色
function SystemSpecial (props) {
  const { special } = props
  return (
    <div className="special-prods prods-pane">
      <p className="title">系统特色</p>
      <div className="progress-step">
        {
           special.map((item, index)=> (
            <div className="first" key={index.toString()}>
              <i className={item.iconName}></i>
              {item.text.map(text=> (<p className="text" key={text}>{ text }</p>))}
          </div>
          ))
        }
      </div>
    </div>
  )
}
// 应用场景
function Scene (props) {
  const { scene } = props
  return (
    <div className="scene-prods prods-pane">
      <p className="title">应用场景</p>
      <div className="progress-step padding15">
        {
          scene.map((item, index)=> (
            <div className="first" key={ index.toString() }>
              <i className={ item.iconName }></i>
              <p className="text">{ item.text }</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}
// 信贷审批产品架构
function AuditFramework (props) {
  return (
    <div className="framework-prods prods-pane">
      <p className="title">产品架构图</p>
      <p className="prods-name">亿车云进件信贷管理系统</p>
      <div className="progress-step">
        <div className="item third-party">
          <ul>
            <li>第三方服务</li>
            <li>央行征信</li>
            <li>百融</li>
            <li>fico</li>
            <li>同盾</li>
            <li>手机实名</li>
            <li>人脸识别</li>
            <li>安全服务</li>
            <li>短信服务</li>
            <li>app推送/通知</li>
            <li>统计</li>
            <li>网络服务</li>
            <li>快递接口服务</li>
            <li>电子合同</li>
            <li>其它</li>
          </ul>
        </div>
        <div className="prods-framework-flow">
          <img alt="" src={prodsFrameworkFlow}/>
        </div>
        <div className="item modules">
          <ul>
            <li>贷前模块</li>
            <li>贷中模块</li>
            <li>贷后模块</li>
            <li>订单管理模块</li>
            <li>权限模块管理</li>
            <li>资产/资金方管理模块</li>
            <li>系统管理模块</li>
          </ul>
        </div>
        <div className="prods-framework-flow">
          <img alt="" src={prodsFrameworkFlow}/>
        </div>
        <div className="item third-party">
          <ul>
            <li>e分期系统</li>
            <li style={{ color: '#4a4a4a'}}>征信模块<i className="short-line"></i></li>
            <li>征信接收</li>
            <li>征信查询</li>
            <li>征信查询风险筛查</li>
            <li>准入决策</li>
            <li style={{ color: '#4a4a4a',margin: '15px'}}>审批放款模块 <i className="short-line"></i></li>
            <li>审批决策</li>
            <li>人工审批</li>
            <li>放款模块</li>
            <li style={{ color: '#4a4a4a', margin: '15px'}}>其它模块 <i className="short-line"></i></li>
            <li>通知模块</li>
            <li>电子合同</li>
            <li>其它</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
// 融资租赁产品架构
class LeaseFramework extends React.Component {
  constructor (props) {
    super(props)
    this.state={
      current: 3
    }
  }
  handleClick = (index) => {
    this.setState({
      current: index
    })
  }
  render () {
    const { current } = this.state
    return(
      <div className="framework-prods prods-pane">
      <p className="title">产品架构图</p>
      <div className="lease-framework-content">
        <div className="lease-framework">
          <ul >
            <li  onClick={() => this.handleClick(1)} className={ current === 1 ? 'current':'' }>
            <i className="ywb-icon-rongzizulinxitong-fangwenceng" style={{display: current === 1 ? '':'none'}}></i>
              <p>访问层</p>
            </li>
            <li  onClick={() => this.handleClick(2)} className={ current === 2 ? 'current':'' }>
            <i className="ywb-icon-rongzizulinxitong-yingyongceng" style={{display: current === 2 ? '':'none'}}></i>
             <p>应用层</p>
            </li>
            <li  onClick={() => this.handleClick(3)} className={ current === 3 ? 'current':'' }>
              <i className="ywb-icon-rongzizulinxitong-zhichengceng" style={{display: current === 3 ? '':'none'}}></i>
              <p>支撑层</p>
            </li>
          </ul>
        </div>
        <div className="lease-framework-item" style={{display: current === 3 ? '':'none'}}>
          <div className="item third">
            <i className="ywb-icon-rongzizulinxitong-disanfangzhengxin"></i>
            <p>第三方征信</p>
          </div>
          <div className="item">
            <i className="ywb-icon-rongzizulinxitong-disanfangzhifutongdao"></i>
            <p>第三方支付通道</p>
          </div>
          <div className="item">
            <i className="ywb-icon-rongzizulinxitong-duanxintongdao"></i>
            <p>短信通道</p>
          </div>
          <div className="item">
            <i className="ywb-icon-rongzizulinxitong-dianzihetong"></i>
            <p>电子合同</p>
          </div>
          <div className="item">
            <i className="ywb-icon-rongzizulinxitong-daikoutongdao"></i>
            <p>代扣通道</p>
          </div>
          <div className="item">
            <i className="ywb-icon-rongzizulinxitong-shimingrenzheng"></i>
            <p>实名认证</p>
          </div>
          <div className="item audit">
            <i className="ywb-icon-rongzizulinxitong-fengkongxitong"></i>
            <p>风控系统</p>
          </div>
        </div>
        <div className="lease-framework-item" style={{display: current === 2 ? '':'none'}}>
          <div className="item">
            <i className="ywb-icon-rongzizulinxitong-jichufuwuhoutai"></i>
            <p>基础服务后台</p>
          </div>
          <div className="item">
            <i className="ywb-icon-rongzizulinxitong-yunyingfuwupingtai"></i>
            <p>运营服务平台</p>
          </div>
          <div className="item">
            <i className="ywb-icon-rongzizulinxitong-chezhufuwuguanli"></i>
            <p>车主服务管理</p>
          </div>
          <div className="item">
            <i className="ywb-icon-rongzizulinxitong-jinjianguanlixitongapp"></i>
            <p>进件管理APP</p>
          </div>
          <div className="item">
            <i className="ywb-icon-shouye-rongzizulin"></i>
            <p>融租管理后台</p>
          </div>
          <div className="item">
            <i className="ywb-icon-rongzizulinxitong-zichanguanlihoutai"></i>
            <p>资产管理后台</p>
          </div>
        </div>
        <div className="lease-framework-item"  style={{display: current === 1 ? '':'none', paddingTop: '125px'}}>
          <div className="item fangwen">
            <i className="ywb-icon-rongzizulinxitong-PCduan"></i>
            <p>PC端</p>
          </div>
          <div className="item fangwen">
            <i className="ywb-icon-rongzizulinxitong-yidongduan"></i>
            <p>移动端（Android IOS）</p>
          </div>
          <div className="item fangwen">
            <i className="ywb-icon-rongzizulinxitong-weixinduan"></i>
            <p>微信端</p>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default class Introduce extends React.Component {
  constructor (props) {
    super(props);
    this.state={
      offsetTop:[],
      scrollTop: 0
    }
    this.$data={
      needSetScroll: 0
    }
  }
  componentDidMount () {
    window.addEventListener('scroll', this.checkPageHasScroll)
    this.getSectionOffsetTop()
  }
  getSectionOffsetTop () {
    let element = this.refs
    const arrOffsetTop = Object.keys(element).map(item=> (this.refs[item].offsetTop))
    localStorage.setItem('arrOffsetTop', arrOffsetTop)
    this.setState({
      offsetTop: arrOffsetTop
    })
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this.checkPageHasScroll)
  }
  checkPageHasScroll = (e) => {
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
    let keys = 0, flag = true
    let arr = this.state.offsetTop
    for(var i = 0; i < arr.length; i++) {
      keys++;
      if(flag) {
        if(scrollTop >= arr[arr.length - keys] - 350) {
          // 设置值
          this.setScroll(keys)
          flag = false;
        } else {
          flag = true;
        }
      }
    }
  }
  setScroll (key) {
    const { offsetTop } = this.state
    if(key !== this.$data.needSetScroll){
      this.props.fun(offsetTop.length - key)
      this.$data.needSetScroll = key
    }
  }
  isShowFrameworkModule (option) {
    const { frameworkName } = option

    if(frameworkName === 'audit') {
      return (
        <div className="nav-item-content">
          {/* 架构图 */}
          <section className="framework" ref="framework">
            <AuditFramework />
          </section>
        </div>
      )
    } else {
      return (
        <div className="nav-item-content">
          {/* 架构图 */}
          <section className="framework" ref="framework">
            <LeaseFramework />
          </section>
        </div>
      )
    }
  }
  render () {
    const { introduce, progress, special, scene, framework} = this.props.content
    return (
      <React.Fragment>
        <div className="nav-item-content">
          {/* 产品介绍 */}
          <section className="introduce" ref="introduce">
            <IntroduceProds introduce={ introduce } />
          </section>
        </div>
        <div className="nav-item-content">
          {/* 流程介绍 */}
          <section className="progress" ref="progress">
            <ProgressProds progress={ progress } />
          </section>
        </div>
        {
          framework && this.isShowFrameworkModule(framework)
        }
        <div className="nav-item-content">
          {/* 系统特色*/}
          <section className="special" ref="special">
            <SystemSpecial special={ special } />
          </section>
        </div>
        <div className="nav-item-content">
          {/* 应用场景*/}
          <section className="scene" ref="scene">
            <Scene scene={ scene } />
          </section>
        </div>
      </React.Fragment>
    )
  }
}


