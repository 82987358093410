import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import './index.scss'

export default class YcCard extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      active: '林润审批系统'
    }
  }
  render () {
    const { className, item } = this.props
    return (
      <div className={`yc-card`}>
        <div className={`card ${className}`}>
          <div className = {`icon ${item.iconClass}`}></div>
          <div className = {'name'}>{item.text}</div>
          <div className="detail">
            <div className = {'brief'}>{item.hoverText}</div> 
            <div className={'btn'}><Button href={item.btnLink}>查看详情</Button></div>
          </div>
        </div>
      </div>
    )
  }
}
