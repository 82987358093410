import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import MiddleContainer from '@/components/MiddleContainer'

const InnerBox = ({ index, content }) => (
  <MiddleContainer>
    <div className='inner'>
      <div className={`left left-${index+1}`}>
        <div className={"title"+index}></div>
        <div className={"content"+index}>
          {content}
        </div>
      </div>
      <div className={`right right-${index+1}`}>
        <div className={"img"+index}></div>
      </div>
    </div>
  </MiddleContainer>
)

export default InnerBox

InnerBox.propTypes = {
  content: PropTypes.node
}
