import React, { Component } from 'react'
import { Form, Input, Select } from 'antd'
import { regexRuleCreater } from '@/utils/validate'

const { Option } = Select

const decoratorOptions = (requiredMsg, rules = [], others = {}) => ({
  rules: [
    { required: true, whitespace: true, message: requiredMsg }
  ].concat(rules),
  ...others
})

const productOpts = () => ([
  { value: '1', label: '信贷审批系统' },
  { value: '2', label: '银行面签系统' },
  { value: '3', label: '融资租赁系统' },
  { value: '4', label: '自定义表单系统' },
])
const initProduct = {
  '/products/audit.html': { initialValue: '1' },
  '/products/sign.html': { initialValue: '2' },
  '/products/lease.html': { initialValue: '3' },
}

const fieldItemsConfig = [
  { prop: 'full_name', itemProps: {}, optArgs: [], Component: Input, fieldProps: { placeholder: '请输入姓名', maxLength: 40 } },
  { prop: 'mobile', itemProps: {}, optArgs: [regexRuleCreater('telphone')], Component: Input, fieldProps: { placeholder: '请输入手机号', maxLength: 11 } },
  { prop: 'company_name', itemProps: {}, optArgs: [[{min: 2, max: 60, message: '公司名称长度为2-60字符'}]], Component: Input, fieldProps: { placeholder: '请输入公司名称' } },
  { prop: 'busness_type', itemProps: {}, optArgs: () => ([[], initProduct[window.location.pathname] ? initProduct[window.location.pathname] : {}]), Component: Select, ChildComp: Option, fieldData: productOpts(), fieldProps: { placeholder: '请选择产品' }
  }
]

export default class SendInfos extends Component {
  constructor (props) {
    super(props)
    this.$data = {
      fieldItemsConfig
    }
  }
  getFieldItems () {
    const { getFieldDecorator } = this.props.form;
    return this.$data.fieldItemsConfig.map((item, index) => {
      const optArgs = typeof item.optArgs === 'function' ? item.optArgs() : item.optArgs
      const options = decoratorOptions(item.fieldProps.placeholder, ...optArgs)
      const { Component, ChildComp, fieldData } = item
      return (
        <Form.Item {...item.itemProps} key={index}>
          {getFieldDecorator(item.prop, options)(
            <Component {...item.fieldProps}>
              {ChildComp && fieldData.map((fitem, fidx) => (<ChildComp value={fitem.value} key={fidx}>{fitem.label}</ChildComp>))}
            </Component>
            )}
        </Form.Item>
      )
    })
  }
  render () {
    const FormItems = this.getFieldItems()
    return (
      <div className="send-info-fields">{FormItems}</div>
    )
  }
}