import React, { PureComponent, Fragment } from 'react'
import RouteView, { routes } from '@/router'
import { ThemeContext, ProbationContext } from '@/contexts'
import Helmet from 'react-helmet'
import Header from './Header'
import Footer from './Footer'
import Probation from './Probation'
import TryAndMap from './TryAndMap'
import './index.scss'



export default class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bottomFiexed: true,
      probationVisble: false
    };
    this.footer = React.createRef();
  }
  componentDidMount () {
    window.addEventListener('resize', this.checkPageHasScroll)
    this.checkPageHasScroll()
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.checkPageHasScroll)
  }
  checkPageHasScroll = (e) => {
    const { scrollHeight, offsetHeight } = window.document.body
    const footerHeight = this.footer.current.el.current.offsetHeight
    this.setState({ bottomFiexed: !(scrollHeight > offsetHeight + footerHeight) })
  }
  onProbationSwitch = () => {
    this.setState({ probationVisble: !this.state.probationVisble })
  }
  render () {
    const { bottomFiexed, probationVisble } = this.state
    return (
      <Fragment>
        <Helmet>
          <title>亿车数科——汽车数字科技服务平台</title>
          <meta name="keywords" content="亿车数科、亿车科技、亿车、天津亿车、杭州亿车、资金、资产、数字科技、汽车金融、汽车科技、互联网金融、互联网科技、互联网信贷、金融平台、金融科技、大数据平台、大数据中心、金融生态连接器、投融资平台、资金资产撮合、资产管理平台、信贷审核、银行面签、三亲见、融资租赁、风控系统、供应链金融服务平台、车金融服务系统、贷后平台、车商、亿车商、二手车、新车、工具、车源、金融产品、GPS 汽车保险、抵押解押备案、车辆评估、车型库、违章查询、贷后对接、软件服务、车主保险、客户风险查询、二手车评估、车300评估、精真估评估、VIN码查询" />
          <meta name="description" content="亿车数科，数字科技让汽车生活更简单，提供专业、高效、全方位的汽车数字科技服务" />
        </Helmet>
        <div className={`yc-page-container ${this.props.className}`}>
          <ThemeContext.Provider value="dark">
            {probationVisble && <Probation visible={true} onCancel={this.onProbationSwitch}></Probation> }
            <Header></Header>
            <ProbationContext.Provider value={this.onProbationSwitch} >
              <RouteView routes={routes}></RouteView>
            </ProbationContext.Provider>
            <TryAndMap probationShow={this.onProbationSwitch}></TryAndMap>
            {/*className={ bottomFiexed ? 'is-fixed' : '' }*/}
            <Footer ref={this.footer}></Footer>
          </ThemeContext.Provider>
        </div>
      </Fragment>
    )
  }
}