import React from 'react'
import PropTypes from 'prop-types';
import { ProbationContext } from '@/contexts'
import Introduce from '../Introduce'
import Introduce2 from '../Introduce2'
import './index.scss'

class ProdsNav extends React.Component {
  static contextType = ProbationContext;
  constructor (props) {
    super(props)
    this.state = {
      marginLeft: '',
      needFixed: false,
      activeLeft: 0,
      current: 0,
      fixedStyle: {
        position: 'fixed',
        top: 0,
        zIndex: 200,
        backgroundColor: '#eceef3'
      }
    }
    this.$data={
      needFixedFlag: false
    }
  }
  componentDidMount () {
    this.navMarginLft()
    window.addEventListener('scroll', this.checkPageHasScroll)
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this.checkPageHasScroll)
  }
  checkPageHasScroll = (e) => {
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
    let navTop =  this.refs.nav.offsetTop
    if(scrollTop >= navTop-100 && scrollTop !==0) {
      this.$data.needFixedFlag = true
    } else {
      this.$data.needFixedFlag = false
    }
    this.updateNeedFixed(this.$data.needFixedFlag)
  }
  updateNeedFixed (flag) {
    const { needFixed } = this.state
    if(needFixed !== flag) {
      this.setState({
        needFixed: flag
      })
    }
  }
  // 根据模块计算margin-left值
  navMarginLft () {
    const { navList } = this.props;
    const value = (1200-navList.length * 65) / (navList.length-1) + 'px'
    this.setState({
      marginLeft: value
    })
  }
  setActiveLeft (value) {
    let num = this.props.prodsIntroduce.framework.Num;
    console.log('---',this.props.prodsIntroduce.framework.frameworkName)
    let addNum = this.props.prodsIntroduce.framework.frameworkName == 'faceSign' ? 65 : (num == 3 ? 52 : 55) ;
    let leftValue = value ? value * (parseFloat(this.state.marginLeft) + addNum) : 0
    
    if(value === 1) leftValue = leftValue + 5
    if(value === 2) leftValue = leftValue + 11 
    if(value === 3) leftValue = leftValue + 3
    if(value === 4) leftValue = leftValue - 5
    this.setState({
      activeLeft: leftValue
    })
  }
  handleClickNav= (index) => {
    const localValue = localStorage.getItem('arrOffsetTop').split(',')
    window.scrollTo(0, localValue[index]-250)
  }
  render () {
    const { navList, prodsIntroduce, name } = this.props
    return (
      <React.Fragment>
        <section className="yc-prods-nav" ref="nav" style={this.state.needFixed ?  this.state.fixedStyle: {}}>
          <div className="group">
            <div className="nav-show-box" style={{ display: this.state.needFixed ? '' : 'none'}}>
              <p className="system-name">{ name }</p>
              <div className="try-use btn" onClick={this.context}>申请试用</div>
            </div>
            <nav ref="moduleNav" className="module-nav">
              <ul>
                {
                  navList.map((item, index) => <li  onClick={()=>this.handleClickNav(index)} key={index.toString()} style={{ marginLeft: index === 0 ? '' : this.state.marginLeft, color: this.state.needFixed ? '#000' : ''}}>{ item }</li>)
                }
                <i className="active-item"  style={{ left: this.state.activeLeft }}></i>
              </ul>
            </nav>
          </div>
        </section>
        
        {/* <Introduce content={prodsIntroduce} fun={this.setActiveLeft.bind(this)} current={this.state.current}/> */}
        {
          prodsIntroduce.framework.Num == 5 ?
            <Introduce content={prodsIntroduce} fun={this.setActiveLeft.bind(this)} current={this.state.current}/> :
            <Introduce2 content={prodsIntroduce} fun={this.setActiveLeft.bind(this)} current={this.state.current}/>
        }
        

      </React.Fragment>
    )
  }
}
ProdsNav.propTypes = {
  navList: PropTypes.array.isRequired
};

export default ProdsNav