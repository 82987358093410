import React, { PureComponent, Fragment } from 'react'
import Wrapper from '../Home/components/Wrapper'
import './index.scss'

export default class Dtcenter extends PureComponent {
  render () {
    return (
      <div className="about-page">
        <img className = "img-dtcenter" alt="" src={require('./images/data.png')}></img>
      </div>
    )
  }
}