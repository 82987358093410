import React from 'react'
import ProdsHeaderContainer from '../components/ProdsHeaderContainer'
import ProdsNav from '../components/ProdsNav'
import systemImg from '../images/audit/system-img.png'
import systemNameImg from '../images/audit/chanpin-shenpiwenzi1.png'
import systemDes from '../images/audit/chanpin-shenpiwenzi2.png'
import posterImg from '../images/audit/chanpin-shenpitu.png'

export default function (props) {
  const navList = ['产品介绍', '产品业务流程', '产品架构', '系统特色', '应用场景']
  const introduceContent = {
    introduce: {
      systemName: '亿车云进件信贷审批系统',
      iconName: 'ywb-icon-xindaishenpi',
      text: '亿车云进件系统是一款专门服务于商业银行、汽车金融公司、保险公司和互联网金融公司等主体的的系统平台。为车贷企业提供专业、高效、全能型的系统服务。完善的业务系统、合理的风控体系为汽车金融服务保驾护航。',
      imgUrl: systemImg
    },
    progress: [{
      iconName: 'ywb-icon-yixiangkehu',
      text: '意向客户'
    },{
      iconName: 'ywb-icon-xindaishenpi',
      text: '亿车云进件信贷审批系统'
    },{
      iconName: 'ywb-icon-fangkuan',
      text: '放款'
    }],
    special: [
      {
        iconName: 'ywb-icon-keshihuatubiao',
        text: ['可视化图表管理']
      },{
        iconName: 'ywb-icon-zidingyiliucheng',
        text: ['自定义流程']
      },{
        iconName: 'ywb-icon-zidonghuashenpi',
        text: ['自动化审批']
      },{
        iconName: 'ywb-icon-yijianshengchenghetong1',
        text: ['一键生成合同']
      }
    ],
    scene:[
      {
        iconName: 'ywb-icon-chelianganjie',
        text: '车辆按揭'
      },{
        iconName: 'ywb-icon-cheliangdiya',
        text: '车辆抵押'
      },{
        iconName: 'ywb-icon-zhengxinchaxun',
        text: '征信查询'
      }
    ],
    framework: {
      frameworkName: 'audit', Num:5,
    }
  }
  return (
    <div>
      <ProdsHeaderContainer url={ { systemNameImg, systemDes, posterImg } }/>
      <ProdsNav navList={ navList } prodsIntroduce={ introduceContent } name="亿车云进件信贷审批系统"/>
    </div>
  )
}