import React, { Component } from 'react'
import { Modal, Button, message } from 'antd'
import WrappedFormFields from './components/WrappedFormFields'
import $fetch, { sendUserInfo, sendVerifyCode } from '@/plugins/smartfetch'
import './index.scss'

export default class Probation extends Component {
  constructor (props) {
    super(props);
    this.state = {
      step: 0,
      loading: false,
      telphone: ''
    }
    this.$data = {
      buttonProps: {
        size: 'large',
        block: true
      },
      formData: {}
    }
    this.$fetch = $fetch
  }
  getHandledModelProps (step) {
    return Object.assign({ maskClosable: false, width: step < 2 ? 480 : 680 }, this.props)
  }
  onSubmit = () => {
    const { form } = this.formRef.props
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.onFormSend(values)
    })
  }
  skipStep (step, newState) {
    this.setState({ step, ...newState })
  }
  onFormSend (params) {
    Object.assign(this.$data.formData, params)
    const nextStep = this.state.step + 1
    this.userInfoSend(this.$data.formData, () => {
      this.skipStep(nextStep, nextStep === 1 ? { telphone: params.mobile } : {})
    })
  }
  onVerifyCodeSend = (params) => {
    this.$fetch(sendVerifyCode, { mobile: this.state.telphone }).done(data => {
      !data['is_done'] && message.error('短信发送失败，请稍后重试')
    })
  }
  saveFormRef = formRef => {
    this.formRef = formRef;
  }
  userInfoSend = (params, next) => {
    this.$fetch(sendUserInfo, params).lock('loading').done(data => {
      data['is_done'] ? next() : message.error('数据提交失败，请稍后重试')
    })
  }
  render () {
    const { step, loading, telphone } = this.state
    const { buttonProps } = this.$data
    const handledModelProps = this.getHandledModelProps(step)
    const footer = [<Button className="theme-linear-button" key="submit" htmlType="submit" type="primary" loading={loading} onClick={this.onSubmit}  {...buttonProps}>确定</Button>]
    return (
      <Modal
        wrapClassName="probation-model"
        {...handledModelProps}
        footer={step < 2 ? footer : null}
      >
        {step < 2 ?
          (
            <div>
              <p className="form-title">申请使用</p>
              <WrappedFormFields step={step} telphone={telphone} wrappedComponentRef={this.saveFormRef} onVerifySend={this.onVerifyCodeSend}></WrappedFormFields>
            </div>
          ) : 
          <div className="send-success-message">
            <div className="top-mid-icon"><i className="ywb-icon-wancheng"></i></div>
            <p className="tip">恭喜您申请试用成功！稍后会有工作人员跟您联系</p>
          </div>
        }
      </Modal>
    )
  }
}