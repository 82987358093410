import React from 'react';
import { BrowserRouter } from "react-router-dom";
import Layout from './layout'
import './styles/index.scss';

function App() {
  return (
    <BrowserRouter>
      <Layout className="App">
      </Layout>
    </BrowserRouter>
  );
}

export default App;
